import * as React from 'react';
import { useEffect } from 'react';

import { BusinessReviews, CaseStudies, Company, Contact, Faqs, HeroImage, PropertyTaxServices, TaxProtestProcess } from '../components/home';
import { Footer, NavMenu, PageTitle, TrackSection } from '../components/shared';

import { scroller } from 'react-scroll';
import { useScreenInfo } from '../utils';

const IndexPage: React.FunctionComponent = () => {
  const screen = useScreenInfo();

  useEffect(() => {
    if (window.location.search.includes('wordfence')) {
      window.location.href = '/';
    }

    if (window.location.hash) {
      scroller.scrollTo(window.location.hash.replace(/^#/, ''), { offset: -126 });
    }
  }, []);

  return (
    <>
      <PageTitle />
      <NavMenu />
      <HeroImage />
      <PropertyTaxServices />
      <TrackSection title="Business Reviews" component={<BusinessReviews />} />
      <TrackSection title="Tax Protest Process" component={<TaxProtestProcess />} />
      <TrackSection title="Case Studies" component={<CaseStudies />} />
      <TrackSection title="Faqs" component={<Faqs />} />
      <TrackSection title="Company" component={<Company />} />
      <TrackSection title="Contact" component={<Contact />} />
      <Footer />
    </>
  );
};

export default IndexPage;
